<template>
  <div class="orders-summery">
    <div class="tools-bar-btns" style="width:100%;">
      <Button
        label="הצג הכל"
        class="p-button-secondary"
        style="font-size: 1.2rem; margin-bottom:0.5rem; margin-left:5px; color:#fff"
        @click="ordersShowAll"
      />
      <Button
        label="הצג חדשים"
        class="p-button-info"
        style="font-size: 1.2rem; margin-bottom:0.5rem; margin-left:5px;"
        @click="ordersShowNew"
      />
      <Button label="הצג טופלו" class="p-button-success"
        style="font-size: 1.2rem; margin-bottom:0.5rem; margin-left:5px;"
        @click="ordersShowDone"/>
      <Button label="הצג בביצוע" class="p-button-warning"
        style="font-size: 1.2rem; margin-bottom:0.5rem; margin-left:5px;"
        @click="orderShowDelivery"
      />
      <Button label="הצג שגויים" class="p-button-danger"
        style="font-size: 1.2rem; margin-bottom:0.5rem; margin-left:5px;"
        @click="orderShowProblem"/>
      <Button id="excelBtn" icon="pi pi-file-excel"
        class="p-button-rounded p-button-success p-button-outlined"
        @click="handleExportToCsv"/>
    </div>
    <div class="branches-list">
      <div class="choose-date">
        <p>מתאריך</p>
        <input type="date" v-model="selectedDateFrom" />
      </div>
      <div class="choose-date">
        <p>עד תאריך</p>
        <input type="date" v-model="selectedDateTo" />
      </div>
      <div class="search">
        <span class="p-input-icon-left" style="width:100%">
        <i class="pi pi-search" />
        <InputText
            type="text"
            v-model="search"
            placeholder="חיפוש"
            style="width:100%"
        />
        </span>
      </div>
    </div>

    <template v-for="order in filter_by_search" :key="order.orderId">
      <div class="orders-summery-line" @click="showOrderToPrint(order)">
        <div class="p-field-checkbox" v-if="showCheckbox">
          <Checkbox
            :name="order.branche"
            :value="order"
            v-model="ordersToMerge"
          />
        </div>
        <div
          class="order-status"
          style="display:flex; gap: 10px; cursor: pointer;"
        >
          <p style="font-weight:500;">סטטוס:</p>
          <p v-if="order.status == 'open'" style="color:blue">חדש</p>
          <p v-if="order.status == 'close'" style="color:green">טופל</p>
          <p v-if="order.status == 'delivery'" style="color:orange">
            הועבר לביצוע
          </p>
          <p v-if="order.status == 'problem'" style="color:red">דווח כשגוי</p>
        </div>
        <div class="order-date" style="display:flex; gap: 10px;">
          <p style="font-weight:500;">תאריך:</p>
          <p>{{ order.createdAt.toDate().toLocaleString("he") }}</p>
        </div>
        <div class="order-branch" style="display:flex; gap: 10px;">
          <p style="font-weight:500;">סניף:</p>
          <p>{{ order.branche }}</p>
        </div>
        <div style="display:flex; gap: 10px;">
          <p style="font-weight:500;">שם העובד:</p>
          <p>{{ order.worker_name }}</p>
        </div>
        <div style="display:flex; gap: 10px;">
          <p style="font-weight:500;">מס זהות:</p>
          <p>{{ order.idNumber }}</p>
        </div>
        <div class="order-products" style="display:flex; gap: 10px;">
          <p style="font-weight:500;">מס מוצרים:</p>
          <p>{{ order.items.length }}</p>
        </div>
      </div>
    </template>
  </div>
  <div>

    <!-- close order -->
    <Dialog
      header="סיום הזמנה"
      v-model:visible="showFinishOrder"
      position="top"
    >
      <div
        class="order-title"
        style="display:flex; justify-content:center; flex-direction:column; gap:10px; align-items:center; font-family: 'Rubik', sans-serif;"
      >
        <p style="font-size:1.5rem;">
          קבלת הביגוד לידי: סניף {{ orderToPrint.branche }}
        </p>

        <p style="font-size:1.25rem;">תאריך: {{ currentDate }}</p>
      </div>

      <div class="introduction">
        <h4>עובד רוסמן יקר/ה!</h4>
        <p>
          בהזמנתך הנך מאשר בזאת כי קיבלת לרשותך ביגוד ו/או הנעלה כשהוא חדש ושלם
          וללא כל פגם.
        </p>
        <p>
          ידוע לך כי קבלת הציוד הינה לטובת פעילותך עבור המעסיק והנך מצהיר
          ומתחייב להשתמש בציוד אך ורק לצורך פעילותך ועבודתך אצל המעסיק.
        </p>
        <p>
          באם הנך עובד חדש (עד 60 יום מתאריך תחילת עבודתך) אתה מתחייב להשיב את
          הציוד למעסיק עם סיום עבודתך אצלו מכל סיבה שהיא ו/או מיד עם דרישתו
          הראשונה כשהוא שלם ותקין, ללא כל פגם, למעט בלאי הנובע משימוש סביר
          ורגיל, בהתאם להוראות יצרן הציוד ו/או המעסיק.
        </p>
        <p>
          באם הציוד יינזק ו/או ייגנב ו/או ייעלם מכל סיבה שהיא, אחויב בסכום השווה
          לשווי ציוד חדש כפי ערכו ברכישה מהספק ו/או היבואן הראשי.
        </p>
        <p>התחייבותך הנ"ל הינן בלתי חוזרות.</p>
      </div>

      <div class="details-order">
        <p>שם העובד: {{ orderToPrint.worker_name }}</p>
        <p>ת"ז: {{ orderToPrint.idNumber }}</p>
        <p>תפקיד: {{ orderToPrint.worker_role }}</p>
      </div>

      <div
        class="products-list-order"
        style="display:flex; flex-direction:column; gap: 10px; margin-top:1rem; width:100%; direction:rtl; font-family: 'Rubik', sans-serif;"
      >
        <div
          class="orders-title"
          style="display:flex; margin-top:1rem; width:100%; text-align:center; font-size:1.3rem; font-weight:500;"
        >
          <p style="width:25%">קוד</p>
          <p style="width:25%">שם ביגוד</p>
          <p style="width:25%">מידה</p>
          <p style="width:25%">כמות</p>
        </div>
        <div class="line-sep" style="border:1px solid grey; width:100%;"></div>
        <template v-for="product in orderToPrint.items" :key="product.barkod">
          <div
            class="product-order"
            style="display:flex; text-align:center; width:100%; font-size:1.25rem;"
          >
            <p style="width:25%">{{ product.barkod }}</p>
            <p style="width:25%">{{ product.ItemName }}</p>
            <p style="width:25%">{{ product.selectedSizes }}</p>
            <p style="width:25%">{{ product.quantity }}</p>
          </div>
          <div
            class="line-sep"
            style="border:1px solid grey; width:100%; margin-bottom:10px;"
          ></div>
        </template>
      </div>

      <div class="finish-btn">
        <Button
          id="print-order-hide"
          label="סיים"
          style="width:140px; height:40px; font-size: 1.1rem;"
          class="p-button-success"
          @click="flagOrderFinished"
        />
      </div>

      <!-- just for email -->
      <div
        id="to-email"
        style="font-family: 'Rubik', sans-serif; direction:rtl; width:100%;"
        v-show="false"
      >
        <div style="display:flex; flex-direction: column; margin:1px;">
          <p style="font-size:1.5rem; margin:1px; line-height:1.5rem;">
            קבלת הביגוד לידי: סניף {{ orderToPrint.branche }}
          </p>
        </div>
        <p
          style="font-size:1.25rem; margin:1px; line-height:1.5rem; line-height:1.5rem;"
        >
          תאריך: {{ currentDate }}
        </p>

        <div class="introduction">
          <h4>עובד רוסמן יקר/ה!</h4>
          <p>
            בהזמנתך הנך מאשר בזאת כי קיבלת לרשותך ביגוד ו/או הנעלה כשהוא חדש
            ושלם וללא כל פגם.
          </p>
          <p>
            ידוע לך כי קבלת הציוד הינה לטובת פעילותך עבור המעסיק והנך מצהיר
            ומתחייב להשתמש בציוד אך ורק לצורך פעילותך ועבודתך אצל המעסיק.
          </p>
          <p>
            באם הנך עובד חדש (עד 60 יום מתאריך תחילת עבודתך) אתה מתחייב להשיב את
            הציוד למעסיק עם סיום עבודתך אצלו מכל סיבה שהיא ו/או מיד עם דרישתו
            הראשונה כשהוא שלם ותקין, ללא כל פגם, למעט בלאי הנובע משימוש סביר
            ורגיל, בהתאם להוראות יצרן הציוד ו/או המעסיק.
          </p>
          <p>
            באם הציוד יינזק ו/או ייגנב ו/או ייעלם מכל סיבה שהיא, אחויב בסכום
            השווה לשווי ציוד חדש כפי ערכו ברכישה מהספק ו/או היבואן הראשי.
          </p>
          <p>התחייבותך הנ"ל הינן בלתי חוזרות.</p>
        </div>

        <div class="details-order">
          <p>שם העובד: {{ orderToPrint.worker_name }}</p>
          <p>ת"ז: {{ orderToPrint.idNumber }}</p>
          <p>תפקיד: {{ orderToPrint.worker_role }}</p>
        </div>

        <div style="display:flex; margin-top: 1rem; width: 100%;">
          <table style="margin:1px; width:100%;">
            <tr style="width:100%;">
              <th style="width:25%">קוד</th>
              <th style="width:25%">שם ביגוד</th>
              <th style="width:25%">מידה</th>
              <th style="width:25%">כמות</th>
            </tr>
            <template
              v-for="product in orderToPrint.items"
              :key="product.barkod"
            >
              <tr style="width:100%;">
                <td style="width:25%; text-align:center;">
                  {{ product.barkod }}
                </td>
                <td style="width:25%; text-align:center;">
                  {{ product.ItemName }}
                </td>
                <td style="width:25%; text-align:center;">
                  {{ product.selectedSizes }}
                </td>
                <td style="width:25%; text-align:center;">
                  {{ product.quantity }}
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </Dialog>

    <!-- problem with order -->
    <Dialog
      header="דיווח על בעיה"
      v-model:visible="showProblemWithOrder"
      position="top"
      style="width:90vw;"
    >
      <div style="height:80vh">
        <h1>דיווח על בעיה</h1>
        <h3>במסך זה יש למלא את את כל שלושת העמודות</h3>
        <h4>באפשרותך למלא שורה אחת או יותר לבחירתך</h4>
        <div class="container-problem" style="margin-top:20px;">
          <div class="headers" style="display:flex;">
            <p style="width:25%; text-align:center;font-weight: bold;">
              שם פריט
            </p>
            <p style="width:25%; text-align:center;font-weight: bold;">
              סיבת הבעיה
            </p>
            <p style="width:25%; text-align:center;font-weight: bold;">כמות</p>
          </div>
          <div
            class="reasons"
            style="display:flex; margin-top:15px; justify-content:space-between; "
          >
            <Dropdown
              v-model="problemsData.item0"
              :options="itemsToDisplay"
              placeholder="תיאור המוצר"
              style="width:25%; margin-left:4px;"
            />
            <Dropdown
              v-model="problemsData.reason0"
              :options="reasonsForProblems"
              placeholder="סיבת הבעיה"
              style="width:25%; margin-left:4px;"
            />
            <Dropdown
              v-model="problemsData.qnt0"
              :options="quantitiesForProblems"
              placeholder="כמות"
              style="width:25%;  margin-left:4px;"
            />
            <Button
              label="הוסף סיבה"
              class="p-button-success"
              icon="pi pi-plus"
              style="width:25%"
              @click="handleAddReasonProblem"
            />
            <!-- moshe -->
          </div>
          <template
            v-for="(reason, index) in reasonsProblemArray"
            :key="reason"
          >
            <div
              class="reasons"
              style="display:flex; margin-top:15px; justify-content:space-between; "
            >
              <Dropdown
                v-model="problemsData[`item${index + 1}`]"
                :options="itemsToDisplay"
                placeholder="תיאור המוצר"
                style="width:25%; margin-left:4px;"
              />
              <Dropdown
                v-model="problemsData[`reason${index + 1}`]"
                :options="reasonsForProblems"
                placeholder="סיבת הבעיה"
                style="width:25%; margin-left:4px;"
              />
              <Dropdown
                v-model="problemsData[`qnt${index + 1}`]"
                :options="quantitiesForProblems"
                placeholder="כמות"
                style="width:25%;  margin-left:4px;"
              />
              <div style="width:25%;">
                <Button
                  label="הסר"
                  class="p-button-danger"
                  icon="pi pi-minus"
                  style="width:100%;"
                  @click="handleRemoveReasonProblem"
                />
              </div>

              <!-- moshe -->
            </div>
          </template>
        </div>
        <div
          style="position:fixed; bottom:0; left:10px; width:100%; text-align:center;"
        >
          <Button
            label="דווח"
            class="p-button-danger"
            style="width:95%;"
            @click="handleProblemWithItem"
          />
        </div>

        <div id="problem" v-show="false">
          <h1>דיווח על הזמנת ביגוד שגויה בסניף {{ orderToPrint.branche }}</h1>
          <p>
            שם המזמין:{{ orderToPrint.owner }}; דוא"ל:{{ orderToPrint.email }}
          </p>
          <p>שם העובד:{{ orderToPrint.worker_name }}</p>
          <p>להלן הערות:</p>
          <p style="color:red;">{{ problemWithItem }}</p>
        </div>
      </div>
    </Dialog>
    <!-- orders to print -->
    <Dialog
      header="הזמנה"
      v-model:visible="showOrderPrint"
      :modal="true"
      position="top"
      :style="{ width: '90vw' }"
    >
      <div id="orderToShow" class="orderToShow">
        <div class="print">
          <Button
            id="print-order-hide"
            label="הדפס הזמנה"
            style="width:140px; height:40px; font-size: 1.1rem;"
            class="p-button-success"
            @click="printOrder"
          />
        </div>
        <div
          class="order-title"
          style="display:flex; justify-content:center; flex-direction:column; gap:10px; align-items:center; font-family: 'Rubik', sans-serif;"
        >
          <p style="font-size:1.5rem;">
            הזמנה ביגוד והנעלה חדשה: סניף {{ orderToPrint.branche }}
          </p>
          <p style="font-size:1.25rem;">
            תאריך: {{ orderToPrint.createdAt.toDate().toLocaleString() }}
          </p>
        </div>

        <div class="introduction">
          <h4>עובד רוסמן יקר/ה!</h4>
          <p>
            בהזמנתך הנך מאשר בזאת כי קיבלת לרשותך ביגוד ו/או הנעלה כשהוא חדש
            ושלם וללא כל פגם.
          </p>
          <p>
            ידוע לך כי קבלת הציוד הינה לטובת פעילותך עבור המעסיק והנך מצהיר
            ומתחייב להשתמש בציוד אך ורק לצורך פעילותך ועבודתך אצל המעסיק.
          </p>
          <p>
            באם הנך עובד חדש (עד 60 יום מתאריך תחילת עבודתך) אתה מתחייב להשיב את
            הציוד למעסיק עם סיום עבודתך אצלו מכל סיבה שהיא ו/או מיד עם דרישתו
            הראשונה כשהוא שלם ותקין, ללא כל פגם, למעט בלאי הנובע משימוש סביר
            ורגיל, בהתאם להוראות יצרן הציוד ו/או המעסיק.
          </p>
          <p>
            באם הציוד יינזק ו/או ייגנב ו/או ייעלם מכל סיבה שהיא, אחויב בסכום
            השווה לשווי ציוד חדש כפי ערכו ברכישה מהספק ו/או היבואן הראשי.
          </p>
          <p>התחייבותך הנ"ל הינן בלתי חוזרות.</p>
        </div>
        <div
          v-if="orderToPrint.problem"
          class="introduction"
          style="color:red;"
        >
          <p>הזמנה זו דווחה כשגוייה</p>
          <p>{{ orderToPrint.problem }}</p>
        </div>
        <div class="details-order">
          <p>שם העובד: {{ orderToPrint.worker_name }}</p>
          <p>ת"ז: {{ orderToPrint.idNumber }}</p>
          <p>תפקיד: {{ orderToPrint.worker_role }}</p>
        </div>
        <div
          class="notes-order"
          style="display:flex; gap:5px; flex-direction:column; margin-top:10px; background-color:whitesmoke; border-radius:5px; font-family: 'Rubik', sans-serif;"
        ></div>
        <div
          class="products-list-order"
          style="display:flex; flex-direction:column; gap: 10px; margin-top:1rem; width:100%; direction:rtl; font-family: 'Rubik', sans-serif;"
        >
          <div
            class="orders-title"
            style="display:flex; margin-top:1rem; width:100%; text-align:center; font-size:1.3rem; font-weight:500;"
          >
            <p style="width:25%">קוד</p>
            <p style="width:25%">שם ביגוד</p>
            <p style="width:25%">מידה</p>
            <p style="width:25%">כמות</p>
          </div>
          <div
            class="line-sep"
            style="border:1px solid grey; width:100%;"
          ></div>
          <template v-for="product in orderToPrint.items" :key="product.barkod">
            <div
              class="product-order"
              style="display:flex; text-align:center; width:100%; font-size:1.25rem;"
            >
              <p style="width:25%">{{ product.barkod }}</p>
              <p style="width:25%">{{ product.ItemName }}</p>
              <p style="width:25%">{{ product.selectedSizes }}</p>
              <p style="width:25%">{{ product.quantity }}</p>
            </div>
            <div
              class="line-sep"
              style="border:1px solid grey; width:100%; margin-bottom:10px;"
            ></div>
          </template>
          <div v-if="orderToPrint.signature" class="signature">
            <p>חתימת העובד:</p>
            <img :src="orderToPrint.signature" />
          </div>
        </div>
        <div
          class="print-tools"
          style="width:90vw; display:flex; gap:10px; justify-content:center; margin-top:1rem;"
        >
          <Button
            v-if="orderToPrint.status == 'delivery'"
            id="mark-btn-hide"
            label="הביגוד הגיע"
            style="width:140px; height:40px; font-size: 1.1rem;"
            class="p-button-success"
            @click="showFinishOrder = !showFinishOrder"
          />
          <Button
            v-if="orderToPrint.status == 'delivery'"
            id="mark-btn-hide"
            label="דווח על בעיה"
            style="width:140px; height:40px; font-size: 1.1rem;"
            class="p-button-danger"
            @click="handleShowProblemWithOrder"
          />
        </div>
      </div>

      <!-- just for print -->
      <div id="orderToPrint" v-show="false">
        <div
          class="order-title"
          style="display:flex; justify-content:center; flex-direction:column; gap:10px; align-items:center; font-family: 'Rubik', sans-serif;"
        >
          <p style="font-size:1.5rem;">
            הזמנה ביגוד והנעלה חדשה: סניף {{ orderToPrint.branche }}
          </p>
          <p style="font-size:1.25rem;">
            תאריך: {{ orderToPrint.createdAt.toDate().toLocaleString() }}
          </p>
        </div>

        <div class="introduction" style=" direction: rtl;">
          <h4>עובד רוסמן יקר/ה!</h4>
          <p>
            בהזמנתך הנך מאשר בזאת כי קיבלת לרשותך ביגוד ו/או הנעלה כשהוא חדש
            ושלם וללא כל פגם.
          </p>
          <p>
            ידוע לך כי קבלת הציוד הינה לטובת פעילותך עבור המעסיק והנך מצהיר
            ומתחייב להשתמש בציוד אך ורק לצורך פעילותך ועבודתך אצל המעסיק.
          </p>
          <p>
            באם הנך עובד חדש (עד 60 יום מתאריך תחילת עבודתך) אתה מתחייב להשיב את
            הציוד למעסיק עם סיום עבודתך אצלו מכל סיבה שהיא ו/או מיד עם דרישתו
            הראשונה כשהוא שלם ותקין, ללא כל פגם, למעט בלאי הנובע משימוש סביר
            ורגיל, בהתאם להוראות יצרן הציוד ו/או המעסיק.
          </p>
          <p>
            באם הציוד יינזק ו/או ייגנב ו/או ייעלם מכל סיבה שהיא, אחויב בסכום
            השווה לשווי ציוד חדש כפי ערכו ברכישה מהספק ו/או היבואן הראשי.
          </p>
          <p>התחייבותך הנ"ל הינן בלתי חוזרות.</p>
        </div>

        <div
          class="details-order"
          style=" direction: rtl; line-height: normal;"
        >
          <strong
            ><p style="margin:0">
              שם העובד: {{ orderToPrint.worker_name }}
            </p></strong
          >
          <strong
            ><p style="margin:0">ת"ז: {{ orderToPrint.idNumber }}</p></strong
          >
          <strong
            ><p style="margin:0">
              תפקיד: {{ orderToPrint.worker_role }}
            </p></strong
          >
        </div>

        <div
          class="products-list-order"
          style="display:flex; flex-direction:column; gap: 10px; margin-top:1rem; width:100%; direction:rtl; font-family: 'Rubik', sans-serif;"
        >
          <div
            class="orders-title"
            style="display:flex; width:100%; text-align:center; font-size:1.3rem; font-weight:500; margin:0;"
          >
            <p style="width:25%; ">קוד</p>
            <p style="width:25%">שם ביגוד</p>
            <p style="width:25%">מידה</p>
            <p style="width:25%">כמות</p>
          </div>
          <div
            class="line-sep"
            style="border:1px solid grey; width:100%; margin:0"
          ></div>

          <template v-for="product in orderToPrint.items" :key="product.barkod">
            <div
              class="product-order"
              style="display:flex; text-align:center; width:100%; font-size:1.25rem;"
            >
              <p style="width:25%; margin:0;">{{ product.barkod }}</p>
              <p style="width:25%; margin:0;">{{ product.ItemName }}</p>
              <p style="width:25%; margin:0;">{{ product.selectedSizes }}</p>
              <p style="width:25%; margin:0;">{{ product.quantity }}</p>
            </div>
            <div
              class="line-sep"
              style="border:1px solid grey; width:100%;"
            ></div>
          </template>
           <div
            v-if="orderToPrint.signature"
            class="signature"
            style="border: 1px solid black; margin-top:5px;"
          >
            <p>חתימת העובד:</p>
             <div class="sign"></div>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import Dialog from "primevue/dialog";
import {projectFirestore,projectFunctions} from '../../../../firebase/config'
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import XLSX from "xlsx";
import store from '../../../../store';
import {update_status_counter} from '../../../../Methods/bigud_funcs'
import {alert} from '../../../../Methods/Msgs'


/* eslint-disable */
export default {
  components:{Dialog,Checkbox,Dropdown,InputText},
  setup(){
    const search = ref('')
    const user = ref(computed(()=>{
      return store.getters.user
    }))
    const role = ref(computed(()=>{
        return store.getters.role
    }))
    const problemWithItem = ref('')
    const showProblemWithOrder = ref(false)
    const selectedDateFrom = ref(null)
    const selectedDateTo = ref(null)
    const showCheckbox=ref(false)
    const ordersToMerge=ref([])
    const orders=ref([])
    const tempOrders=ref([])
    const ordersFilter=ref('')
    const showOrderPrint = ref(false);
    const orderToPrint = ref(null)
    const showFinishOrder = ref(false)
    const reasonsProblemArray=ref([])

    const handleShowProblemWithOrder=()=>{
      showProblemWithOrder.value=!showProblemWithOrder.value
      problemsData.value={}
      reasonsProblemArray.value=[]
    }

    const itemsToDisplay=ref(computed(()=>{
      const tempArr=[]
      if(orderToPrint.value){
         orderToPrint.value.items.forEach(item=>{
          tempArr.push(item.ItemName)
        })
      }
    
      return tempArr
    }))

    const reasonsForProblems=ref([
      'אחד או יותר מהפריטים אינו תואם במידה',
      'פריטי/ים פגומ/ים',
      'חסר/ים פריט/ים',
      'ביגוד שאינו תואם את לוגו הסניף'
    ])

    const quantitiesForProblems=ref([
      1,2,3,4
    ])

    const handleAddReasonProblem=()=>{
      reasonsProblemArray.value.push(1)
    }

    const handleRemoveReasonProblem=()=>{
      reasonsProblemArray.value.pop()
    }

    const problemsData=ref({})

    const currentDate=ref(computed(()=>{
        let today = new Date();
        let time = String(today.getHours())+":"+String(today.getMinutes())+":"+String(today.getSeconds())
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        today = dd + '/' + mm + '/' + yyyy+" "+time
        return today
    }))
    

    const handleProblemWithItem = async()=>{
      const size = Object.keys(problemsData.value).length/3;
      for(let i=0; i<size; i++){
        problemWithItem.value+="מוצר:"+problemsData.value[`item${i}`]+","+"סיבה:"+problemsData.value[`reason${i}`]+","+"כמות:"+problemsData.value[`qnt${i}`]+"\n"
      }
      let docId;
      try{
        const res= await projectFirestore
        .collection("Applications")
        .doc("wH3Q9T5lhhJ0PsdpBN1J")
        .collection("Orders")
        .where("orderId", "==", orderToPrint.value.orderId)
        .get()

        res.forEach(doc=>{
          docId = doc.id
        })

      
        await projectFirestore
        .collection("Applications")
        .doc("wH3Q9T5lhhJ0PsdpBN1J")
        .collection("Orders")
        .doc(docId)
        .set({
          status:"problem",
          problem: problemWithItem.value
        },{merge:true})
        
        alert('success','סטטוס הזמנה','הפכת הזמנה זו לשגויה')
        .then(async()=>{
            await update_status_counter(orderToPrint.value.status,'problem','wH3Q9T5lhhJ0PsdpBN1J')
            //send email
            sendEmailProblem()
            showProblemWithOrder.value=!showProblemWithOrder.value
            showOrderPrint.value=!showOrderPrint.value
        })
     
      }catch(err){
        alert('error','אירעה שגיאה',`${err.message}`)
      }
    }

    const filter_by_search = ref(computed(()=>{
      if(search.value == '') return filteredOrders.value
      return filteredOrders.value.filter(record=>{
        if(record.owner.includes(search.value)) return record
        if(record.worker_name.includes(search.value)) return record
        if(record.worker_name.includes(search.value)) return record
        if(record.idNumber.includes(search.value)) return record
        if(record.branche.includes(search.value)) return record
      })
    }))
    const filteredOrders = ref(computed(() => {
        if(ordersFilter.value == ''){
          return orders.value
        }
        if(ordersFilter.value == 'open'){
          return orders.value.filter(order => {
            return order.status == 'open'
          })
        }
        if(ordersFilter.value == 'close'){
          return orders.value.filter(order => {
            return order.status == 'close'
          })
        }
        if(ordersFilter.value == 'delivery'){
          return orders.value.filter(order => {
            return order.status == 'delivery'
          })
        }   
        if(ordersFilter.value == 'problem'){
          return orders.value.filter(order => {
            return order.status == 'problem'
          })
        }   
    }))

    const ordersShowAll=()=> {
      ordersFilter.value = "";
    }
    const ordersShowDone=()=> {
      ordersFilter.value = "close";
    }
    const ordersShowNew=()=> {
      ordersFilter.value = "open";
    }
    const orderShowDelivery=()=>{
      ordersFilter.value = "delivery"
    }
    const orderShowProblem=()=>{
      ordersFilter.value = "problem"
    }

    const showOrderToPrint = order=>{
      if(!showCheckbox.value){
       orderToPrint.value = order
       showOrderPrint.value = true
      }
    }

    const flagOrderFinished=async()=>{
      let docId;
      try{
        const res= await projectFirestore
        .collection("Applications")
        .doc("wH3Q9T5lhhJ0PsdpBN1J")
        .collection("Orders")
        .where("orderId", "==", orderToPrint.value.orderId)
        .get()

        res.forEach(doc=>{
          docId = doc.id
        })

        await projectFirestore
        .collection("Applications")
        .doc("wH3Q9T5lhhJ0PsdpBN1J")
        .collection("Orders")
        .doc(docId)
        .set({
          status:"close",
          closeDate:new Date()
        },{merge:true})

        alert('success','סטטוס הזמנה','הפכת סטטוס הזמנה זו לטופל')
        .then(async()=>{
            await update_status_counter(orderToPrint.value.status,'close','wH3Q9T5lhhJ0PsdpBN1J')
            //send email
            sendEmailTest()
            showFinishOrder.value=!showFinishOrder.value
            showOrderPrint.value=!showOrderPrint.value
        })
      }catch(err){
        alert('success','אירעה שגיאה',`${err.message}`)
      }
    }

    function sendEmailTest() {
        projectFunctions.httpsCallable('send_email')({
            html: document.getElementById("to-email").innerHTML,
            destinations:["rosman.mate@gmail.com"],
            from:"התקבלה הזמנת ביגוד חדשה",
            title:`הזמנת ביגוד לסניף ${orderToPrint.value.branche} עבור: ${orderToPrint.value.worker_name} התקבלה בהצלחה`
        })
    }

    function sendEmailProblem() {
        projectFunctions.httpsCallable('send_email')({
            html: document.getElementById("problem").innerHTML,
            destinations:["rosman.mate@gmail.com"],
            from:"דיווח על ביגוד שגוי",
            title:`הזמנת ביגוד לסניף ${orderToPrint.value.branche} עבור: ${orderToPrint.value.worker_name} דווחה כשגויה`
      }) 
    }


    const printOrder=()=>{
      const divContents = document.getElementById("orderToPrint").innerHTML;
      var a = window.open('', '', 'height=800, width=600'); 
      a.document.write('<html>'); 
      a.document.write('<style>'); 
      a.document.write(`@media print{#print-order-hide {visibility: hidden; } .sign{display: list-item; list-style-image: url(${orderToPrint.value.signature}); list-style-position: inside;} #delete-order-hide { visibility: hidden; } #mark-btn-hide {visibility: hidden;}}`)
      a.document.write('</style>'); 
      a.document.write(divContents); 
      a.document.write('</body></html>'); 
      a.document.close(); 
      a.print(); 
    }

     const handleExportToCsv=()=>{
      let rows = JSON.parse(JSON.stringify(orders.value))
      rows.forEach(row=>{
        row.items.forEach((item)=>{
          if(item){
            row[item.ItemName] = item.quantity 
          }
        })
        delete row.id
        delete row.items
        row.createdAt = new Date(row.createdAt.seconds * 1000)
      })
      let workSheet = XLSX.utils.json_to_sheet(rows);
      let workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, workSheet, "סיכום");
      XLSX.writeFile(workbook, "bigud.xlsx");
     }

     watch(selectedDateTo,()=>{
       orders.value = tempOrders.value.filter((order)=>{
         return onlyDate(order.createdAt.toDate())<=onlyDate(new Date(selectedDateTo.value)) && onlyDate(order.createdAt.toDate()) >= onlyDate(new Date(selectedDateFrom.value))
       })
     })

     function onlyDate(dateTime){
       let date = new Date(dateTime.getTime())
       date.setHours(0,0,0,0)
       return date
     }

    let unsub
    const getOrders=()=>{
        const branche_num = user.value.branche_num
        console.log(branche_num);
        if(role.value>=25 && role.value<99 ){
          unsub = projectFirestore
          .collection("Applications")
          .doc("wH3Q9T5lhhJ0PsdpBN1J")
          .collection('Orders')
          .where('branche_num','==',branche_num)
          .orderBy('createdAt','desc').onSnapshot(snap=>{
              let results = [];
              snap.docs.forEach(doc => {
              doc.data().createdAt && results.push({ ...doc.data(), id: doc.id });
          });
          tempOrders.value=results
          orders.value=results

          },err=>{
              console.log(err.message);
              orders.value = null;
          })
        }
        else if(role.value>99){
          unsub = projectFirestore
          .collection("Applications")
          .doc("wH3Q9T5lhhJ0PsdpBN1J")
          .collection('Orders')
          .orderBy('createdAt','desc').onSnapshot(snap=>{
              let results = [];
              snap.docs.forEach(doc => {
              doc.data().createdAt && results.push({ ...doc.data(), id: doc.id });
          });
          tempOrders.value=results
          orders.value=results

          },err=>{
              console.log(err.message);
              orders.value = null;
          })
        }else{
            alert('error','דף זה מיועד לסניפים','אינך יכול לראות דף זה')
        }
    }
    onMounted(async ()=>{
      getOrders()
    })

    onUnmounted(()=>{
        if(unsub){
            unsub()
        }
    })


    return{
      filter_by_search,
      search,
      handleShowProblemWithOrder,
      orderShowProblem,
      handleProblemWithItem,
      problemWithItem,
      showProblemWithOrder,
      currentDate,
      showFinishOrder,
      orderShowDelivery,
      user,
      handleExportToCsv,
      selectedDateFrom,
      selectedDateTo,
      showCheckbox,
      ordersToMerge,
      orders,
      getOrders,
      ordersShowAll,
      ordersShowDone,
      ordersShowNew,
      filteredOrders,
      showOrderToPrint,
      orderToPrint,
      showOrderPrint,
      flagOrderFinished,
      printOrder,
      tempOrders,
      itemsToDisplay,
      reasonsForProblems,
      quantitiesForProblems,
      handleAddReasonProblem,
      reasonsProblemArray,
      handleRemoveReasonProblem,
      problemsData
      
    }
  }
};
</script>

<style scoped>
    .branches-list{
      position: relative;
      display: flex;
      gap: 10px;
    }
    .branches-list .choose-date input{
      border: none;
    }
    .introduction {
      padding: 2rem;
      width: 100%;
      line-height: 1.5;
    }
    .details-order{
        padding: 2rem;
        margin-top: 15px;
        font-weight: 600;
        line-height: 1.5;
    }
    .orders-summery {
      width: 80vw;
      height: 80vh;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .orders-summery-line {
      position: relative;
      width: 100%;
      border: 1px solid rgb(175, 166, 166);
      cursor: pointer;
    }

  .tools-bar-btns{
    position: relative;
    margin-top: 1.2rem;
    display: flex;
  }
  #excelBtn{
    position: absolute;
    left: 0;
    top: 2px;
  }

.p-field-checkbox{
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 5;
}

.finish-btn{
    width: 100%;
    text-align: left;
}





  @media print { 
   
     #page-down {
      page-break-after: always;
      
    } 
    #print-order-hide { 
      visibility: hidden; 
    }
    #delete-order-hide { 
      visibility: hidden; 
    }
    #mark-btn-hide { 
      visibility: hidden; 
    }

  }



</style>
